<template>
<div>
  <main>
    <NavBar :title="dataset.name"
            :titleIcon="'mdi-database'"
            :menuItems= menuItems
            />
    <div class="pl-8 " >
          <v-slide-x-transition mode="out-in">
            <router-view/>
          </v-slide-x-transition>
    </div>
</main>
</div>
</template>

<script>
import {getDataset} from '@/api/dataset'
import NavBar from '@/components/NavBar.vue'

  export default {
    name: 'ManageDataset',
    components: { NavBar},
    data () {
      return {
        menuItems: [
          { title:'DATABASE INFORMATION', routeName:'datasetInfo', routeParams:{ id: this.$route.params.id }} ,
          { title:'IMPORT DATA', routeName:'datasetUpload', routeParams:{ id: this.$route.params.id }} ,
          { title:'MANAGE COLUMNS', routeName:'datasetColumns', routeParams:{ id: this.$route.params.id }},
          { title:'SAMPLES ', routeName:'Samples', routeParams:{}, icon:'mdi-arrow-up-right'} ,
          // { title:'ANALYZE DATABASE', routeName:'analyzeDataset', routeParams:{ id: this.$route.params.id }}
          ],
        dataset:{
          name:'',
        }
      }
    },
    created () {
      const _this = this
      getDataset(this.$route.params.id, function(ds){
        _this.dataset = ds
      })
    }
  }
</script>
